<template>
  <div
    :class="[
      darkModePreference === 'light' &&
        type === 'mobile' &&
        'translate-y-[8px]',
      darkModePreference === 'light' &&
        type === 'desktop' &&
        'translate-y-[5px]',
      darkModePreference === 'dark' &&
        type === 'desktop' &&
        'translate-y-[6px]',
    ]"
  >
    <button
      v-if="darkModePreference === 'dark'"
      title="Light Mode"
      @click="toggleDarkMode()"
    >
      <svg
        v-if="type === 'mobile'"
        xmlns="http://www.w3.org/2000/svg"
        width="3em"
        height="3em"
        viewBox="0 0 512 512"
      >
        <defs>
          <linearGradient
            id="meteoconsSunsetFill0"
            x1="150"
            x2="234"
            y1="119.2"
            y2="264.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#fbbf24" />
            <stop offset=".5" stop-color="#fbbf24" />
            <stop offset="1" stop-color="#f59e0b" />
          </linearGradient>
          <clipPath id="meteoconsSunsetFill1">
            <path
              fill="none"
              d="M512 306H296a21.5 21.5 0 0 0-14 5.3L256 334l-26-22.7a21.5 21.5 0 0 0-14-5.3H0V0h512Z"
            />
          </clipPath>
          <symbol id="meteoconsSunsetFill2" viewBox="0 0 384 384">
            <circle
              cx="192"
              cy="192"
              r="84"
              fill="url(#meteoconsSunsetFill0)"
              stroke="#f8af18"
              stroke-miterlimit="10"
              stroke-width="6"
            />
            <path
              fill="none"
              stroke="#fbbf24"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="24"
              d="M192 61.7V12m0 360v-49.7m92.2-222.5l35-35M64.8 319.2l35.1-35.1m0-184.4l-35-35m254.5 254.5l-35.1-35.1M61.7 192H12m360 0h-49.7"
            >
              <animateTransform
                additive="sum"
                attributeName="transform"
                dur="2s"
                repeatCount="indefinite"
                type="rotate"
                values="0 192 192; 45 192 192"
              />
            </path>
          </symbol>
        </defs>
        <g clip-path="url(#meteoconsSunsetFill1)">
          <use
            width="384"
            height="384"
            href="#meteoconsSunsetFill2"
            transform="translate(64 100)"
          />
        </g>
        <path
          fill="none"
          stroke="#374151"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="18"
          d="M128 332h88l40 36l40-36h88"
        />
      </svg>
      <svg
        v-if="type === 'desktop'"
        xmlns="http://www.w3.org/2000/svg"
        width="3em"
        height="3em"
        viewBox="0 0 512 512"
      >
        <defs>
          <linearGradient
            id="meteoconsSunriseFill0"
            x1="150"
            x2="234"
            y1="119.2"
            y2="264.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#fbbf24" />
            <stop offset=".5" stop-color="#fbbf24" />
            <stop offset="1" stop-color="#f59e0b" />
          </linearGradient>
          <clipPath id="meteoconsSunriseFill1">
            <path
              fill="none"
              d="M512 306H304l-35.9-31.4a18.4 18.4 0 0 0-24.2 0L208 306H0V0h512Z"
            />
          </clipPath>
          <symbol id="meteoconsSunriseFill2" viewBox="0 0 384 384">
            <circle
              cx="192"
              cy="192"
              r="84"
              fill="url(#meteoconsSunriseFill0)"
              stroke="#f8af18"
              stroke-miterlimit="10"
              stroke-width="6"
            />
            <path
              fill="none"
              stroke="#fbbf24"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="24"
              d="M192 61.7V12m0 360v-49.7m92.2-222.5l35-35M64.8 319.2l35.1-35.1m0-184.4l-35-35m254.5 254.5l-35.1-35.1M61.7 192H12m360 0h-49.7"
            >
              <animateTransform
                additive="sum"
                attributeName="transform"
                dur="6s"
                repeatCount="indefinite"
                type="rotate"
                values="0 192 192; 45 192 192"
              />
            </path>
          </symbol>
        </defs>
        <g clip-path="url(#meteoconsSunriseFill1)">
          <use
            width="384"
            height="384"
            href="#meteoconsSunriseFill2"
            transform="translate(64 100)"
          />
        </g>
        <path
          fill="none"
          stroke="#374151"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="18"
          d="M128 332h88l40-36l40 36h88"
        />
      </svg>
    </button>
    <button v-if="darkModePreference === 'light'" title="Dark Mode">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1.8em"
        height="1.8em"
        viewBox="0 0 24 24"
        @click="toggleDarkMode()"
      >
        <g fill="#4b4f5c" fill-opacity="0">
          <path
            d="M15.22 6.03L17.75 4.09L14.56 4L13.5 1L12.44 4L9.25 4.09L11.78 6.03L10.87 9.09L13.5 7.28L16.13 9.09L15.22 6.03Z"
          >
            <animate
              fill="freeze"
              attributeName="fill-opacity"
              begin="0.7s"
              dur="0.4s"
              values="0;1"
            />
          </path>
          <path
            d="M19.61 12.25L21.25 11L19.19 10.95L18.5 9L17.81 10.95L15.75 11L17.39 12.25L16.8 14.23L18.5 13.06L20.2 14.23L19.61 12.25Z"
          >
            <animate
              fill="freeze"
              attributeName="fill-opacity"
              begin="1.1s"
              dur="0.4s"
              values="0;1"
            />
          </path>
        </g>
        <g
          fill-opacity="0"
          stroke="#4b4f5c"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        >
          <path
            fill="#4b4f5c"
            stroke-dasharray="56"
            stroke-dashoffset="56"
            d="M7 6 C7 12.08 11.92 17 18 17 C18.53 17 19.05 16.96 19.56 16.89 C17.95 19.36 15.17 21 12 21 C7.03 21 3 16.97 3 12 C3 8.83 4.64 6.05 7.11 4.44 C7.04 4.95 7 5.47 7 6 Z"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              dur="0.6s"
              values="56;0"
            />
            <animate
              fill="freeze"
              attributeName="fill-opacity"
              begin="1.5s"
              dur="0.5s"
              values="0;1"
            />
          </path>
        </g>
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
const colorMode = useColorMode()

defineProps<{
  type: string
}>()

const darkModePreference = computed(() => {
  if (colorMode.preference === 'system') {
    return 'dark'
  }
  return colorMode.preference
})

const toggleDarkMode = () => {
  switch (darkModePreference.value) {
    case 'dark':
      colorMode.preference = 'light'
      break
    case 'light':
      colorMode.preference = 'dark'
      break
  }
}
</script>
