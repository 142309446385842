<template>
  <footer
    class="footer items-center justify-center gap-6 md:justify-between p-4 text-neutral-content dark:bg-neutral dark:bg-gradient-to-t dark:from-dark_theme_base_300 dark:to-dark_theme_base_100 bg-gradient-to-t from-gray-300 to-gray-100">
    <div class="items-center grid-flow-col text-black dark:text-dark_theme_base_content">
      <p>© {{ getCurrentYear() }} Nikola Ivanović - All rights reserved</p>
    </div>
    <div class="m-auto grid-flow-col gap-4 md:place-self-center md:justify-self-end">
      <template v-for="(socialMediaIcon, idx) in withoutGmailIcon" :key="uuidv4()">
        <span :class="[
        'tooltip tooltip-blue hover:-translate-y-2 transition delay-150 cursor-pointer tooltip-top',
        socialMediaIcon.name === 'CodePen' && 'md:tooltip-left',
      ]" :data-tip="socialMediaIcon.title">
          <a :href="socialMediaIcon.url" target="_blank" :aria-label="socialMediaIcon.name">
            <FontAwesomeIcon
              :icon="['fab', socialMediaIcon.name.toLowerCase() === 'x' ? `${socialMediaIcon.name.toLowerCase()}-twitter` : socialMediaIcon.name.toLowerCase()]"
              :class="[
        'm-2 text-xl',
        socialMediaIcon.name === 'GitHub'
          ? 'text-black'
          : socialMediaIcon.name === 'LinkedIn'
            ? 'text-linkedin'
            : socialMediaIcon.name === 'Twitter'
              ? 'text-twitter'
              : socialMediaIcon.name === 'CodePen'
                ? 'text-black dark:text-white'
                : '',
      ]" />
          </a>
        </span>
      </template>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { socialMediaIcons } from '~/constants'

const withoutGmailIcon = computed(() =>
  socialMediaIcons.filter(
    (socialMediaIcon) => socialMediaIcon.name !== 'Gmail'
  )
)

const getCurrentYear = (): number => new Date().getFullYear();

</script>

<style scoped>
.tooltip.tooltip-blue {
  --tooltip-color: rgb(59 130 246);
  --tooltip-text-color: white;
}

@media (min-width: 280px) {
  p {
    text-align: center !important;
  }
}
</style>
